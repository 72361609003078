// The `GPOS` table contains kerning pairs, among other things.
// https://docs.microsoft.com/en-us/typography/opentype/spec/gpos

import check from '../check';
import { Parser } from '../parse';
import table from '../table';

const subtableParsers = new Array(10);         // subtableParsers[0] is unused

// https://docs.microsoft.com/en-us/typography/opentype/spec/gpos#lookup-type-1-single-adjustment-positioning-subtable
// this = Parser instance
subtableParsers[1] = function parseLookup1() {
    const start = this.offset + this.relativeOffset;
    const posformat = this.parseUShort();
    if (posformat === 1) {
        return {
            posFormat: 1,
            coverage: this.parsePointer(Parser.coverage),
            value: this.parseValueRecord()
        };
    } else if (posformat === 2) {
        return {
            posFormat: 2,
            coverage: this.parsePointer(Parser.coverage),
            values: this.parseValueRecordList()
        };
    }
    check.assert(false, '0x' + start.toString(16) + ': GPOS lookup type 1 format must be 1 or 2.');
};

// https://docs.microsoft.com/en-us/typography/opentype/spec/gpos#lookup-type-2-pair-adjustment-positioning-subtable
subtableParsers[2] = function parseLookup2() {
    const start = this.offset + this.relativeOffset;
    const posFormat = this.parseUShort();
    check.assert(posFormat === 1 || posFormat === 2, '0x' + start.toString(16) + ': GPOS lookup type 2 format must be 1 or 2.');
    const coverage = this.parsePointer(Parser.coverage);
    const valueFormat1 = this.parseUShort();
    const valueFormat2 = this.parseUShort();
    if (posFormat === 1) {
        // Adjustments for Glyph Pairs
        return {
            posFormat: posFormat,
            coverage: coverage,
            valueFormat1: valueFormat1,
            valueFormat2: valueFormat2,
            pairSets: this.parseList(Parser.pointer(Parser.list(function() {
                return {        // pairValueRecord
                    secondGlyph: this.parseUShort(),
                    value1: this.parseValueRecord(valueFormat1),
                    value2: this.parseValueRecord(valueFormat2)
                };
            })))
        };
    } else if (posFormat === 2) {
        const classDef1 = this.parsePointer(Parser.classDef);
        const classDef2 = this.parsePointer(Parser.classDef);
        const class1Count = this.parseUShort();
        const class2Count = this.parseUShort();
        return {
            // Class Pair Adjustment
            posFormat: posFormat,
            coverage: coverage,
            valueFormat1: valueFormat1,
            valueFormat2: valueFormat2,
            classDef1: classDef1,
            classDef2: classDef2,
            class1Count: class1Count,
            class2Count: class2Count,
            classRecords: this.parseList(class1Count, Parser.list(class2Count, function() {
                return {
                    value1: this.parseValueRecord(valueFormat1),
                    value2: this.parseValueRecord(valueFormat2)
                };
            }))
        };
    }
};

subtableParsers[3] = function parseLookup3() { return { error: 'GPOS Lookup 3 not supported' }; };
subtableParsers[4] = function parseLookup4() { return { error: 'GPOS Lookup 4 not supported' }; };
subtableParsers[5] = function parseLookup5() { return { error: 'GPOS Lookup 5 not supported' }; };
subtableParsers[6] = function parseLookup6() { return { error: 'GPOS Lookup 6 not supported' }; };
subtableParsers[7] = function parseLookup7() { return { error: 'GPOS Lookup 7 not supported' }; };
subtableParsers[8] = function parseLookup8() { return { error: 'GPOS Lookup 8 not supported' }; };
subtableParsers[9] = function parseLookup9() { return { error: 'GPOS Lookup 9 not supported' }; };

// https://docs.microsoft.com/en-us/typography/opentype/spec/gpos
function parseGposTable(data, start) {
    start = start || 0;
    const p = new Parser(data, start);
    const tableVersion = p.parseVersion(1);
    check.argument(tableVersion === 1 || tableVersion === 1.1, 'Unsupported GPOS table version ' + tableVersion);

    if (tableVersion === 1) {
        return {
            version: tableVersion,
            scripts: p.parseScriptList(),
            features: p.parseFeatureList(),
            lookups: p.parseLookupList(subtableParsers)
        };
    } else {
        return {
            version: tableVersion,
            scripts: p.parseScriptList(),
            features: p.parseFeatureList(),
            lookups: p.parseLookupList(subtableParsers),
            variations: p.parseFeatureVariationsList()
        };
    }

}

// GPOS Writing //////////////////////////////////////////////
// NOT SUPPORTED

function makeGposTable(kerningPairs) {

    var kerningArray = Object.entries(kerningPairs);
    kerningArray.sort(function (a, b) {
        let aLeftGlyph = parseInt(a[0].match(/\d+/)[0]);
        let aRightGlyph = parseInt(a[0].match(/\d+$/)[0]);
        let bLeftGlyph = parseInt(b[0].match(/\d+/)[0]);
        let bRightGlyph = parseInt(b[0].match(/\d+$/)[0]);
        if (aLeftGlyph < bLeftGlyph) {
            return -1;
        }
        if (aLeftGlyph > bLeftGlyph) {
            return 1;
        }
        if (aRightGlyph < bRightGlyph) {
            return -1;
        }
        return 1;
    });

    const nPairs = kerningArray.length;

    var firstGlyphs = [];
    var kerningGlyphs2 = [];

    for (let i = 0; i < nPairs; i++) {

        let firstGlyph = parseInt(kerningArray[i][0].match(/\d+/)[0]);
        let secondGlyph = parseInt(kerningArray[i][0].match(/\d+$/)[0]);

        if (firstGlyph !== firstGlyphs[firstGlyphs.length - 1]) {
            firstGlyphs.push(firstGlyph);
            kerningGlyphs2[firstGlyphs.length - 1] = [];
        }

        kerningGlyphs2[firstGlyphs.length - 1].push([secondGlyph, kerningArray[i][1]]);
    }

    var result = new table.Table('GPOS', [

        // Start of GPOS Header
        { name: 'majorVersion', type: 'USHORT', value: 1 },
        { name: 'minorVersion', type: 'USHORT', value: 0 },
        { name: 'scriptListOffset', type: 'USHORT', value: 10 },
        { name: 'featureListOffset', type: 'USHORT', value: 48 },
        { name: 'lookupListOffset', type: 'USHORT', value: 62 },

        // Offset #1
        // Start of ScriptList
        { name: 'scriptCount', type: 'USHORT', value: 2 },
        // Script record
        { name: 'scriptTag', type: 'TAG', value: 'DFLT' },
        { name: 'scriptOffset', type: 'USHORT', value: 14 },
        { name: 'scriptTag2', type: 'TAG', value: 'latn' },
        { name: 'scriptOffset2', type: 'USHORT', value: 26 },

        // Start of Script Table #1 (Default)
        { name: 'defaultLangSysOffset', type: 'USHORT', value: 4 },
        { name: 'langSysCount', type: 'USHORT', value: 0 },
        // Start of LangySys Table
        { name: 'lookupOrderOffset', type: 'USHORT', value: 0 }, // Reserved, null
        { name: 'requiredFeatureIndex', type: 'USHORT', value: 65535 },
        { name: 'featureIndexCount', type: 'USHORT', value: 1 },
        { name: 'featureIndex', type: 'USHORT', value: 0 },

        // Start of Script Table #2 (Latin)
        { name: 'defaultLangSysOffset2', type: 'USHORT', value: 4 },
        { name: 'langSysCount2', type: 'USHORT', value: 0 },
        // Start of LangySys Table
        { name: 'lookupOrderOffset2', type: 'USHORT', value: 0 }, // Reserved, null
        { name: 'requiredFeatureIndex2', type: 'USHORT', value: 65535 },
        { name: 'featureIndexCount2', type: 'USHORT', value: 1 },
        { name: 'featureIndex2', type: 'USHORT', value: 0 },

        // Offset #2
        // Start of FeatureList Table
        { name: 'featureCount', type: 'USHORT', value: 1 },
        { name: 'featureTag', type: 'TAG', value: 'kern' },
        { name: 'featureOffset', type: 'USHORT', value: 8 },

        // Start of Feature Table
        { name: 'featureParamsOffset', type: 'USHORT', value: 0 },
        { name: 'lookupIndexCount', type: 'USHORT', value: 1 },
        { name: 'lookupListIndices', type: 'USHORT', value: 0 },

        // Offset #3
        // Start of LookupList Table
        { name: 'lookupCount', type: 'USHORT', value: 1 },
        { name: 'lookupOffset', type: 'USHORT', value: 4 },
        // Start of Lookup table
        { name: 'lookupType', type: 'USHORT', value: 2 },
        { name: 'lookupFlag', type: 'USHORT', value: 0 },
        { name: 'subTableCount', type: 'USHORT', value: 1 },
        { name: 'lookupOffset2', type: 'USHORT', value: 8 },

        // Start of lookup subtable (actual kerning info)
        { name: 'posFormat', type: 'USHORT', value: 1 },
        { name: 'coverageOffset', type: 'USHORT', value: 10 + 4 * firstGlyphs.length + 4 * nPairs },
        // X_ADVANCE only
        { name: 'valueFormat1', type: 'USHORT', value: 4 },
        // Omit (note: using other formats will impact offset calculations)
        { name: 'valueFormat2', type: 'USHORT', value: 0 },
        // pairSetCount: Number of PairSet tables
        { name: 'pairSetCount', type: 'USHORT', value: firstGlyphs.length },
        //   {name: 'pairSetOffsets', type: 'USHORT', value: 22},

    ]);

    var offsetN = 10 + 2 * (firstGlyphs.length);
    for (let i = 0; i < firstGlyphs.length; i++) {
        result.fields.push({ name: 'pairSetOffsets', type: 'USHORT', value: offsetN });
        offsetN = offsetN + 2 + 4 * (kerningGlyphs2[i].length);
    }

    // Add PairSet tables (one for each first letter in a kerning pair)
    for (let i = 0; i < kerningGlyphs2.length; i++) {
        result.fields.push({ name: 'pairValueCount', type: 'USHORT', value: kerningGlyphs2[i].length });
        for (let j = 0; j < kerningGlyphs2[i].length; j++) {
            result.fields.push({ name: 'secondGlyph', type: 'USHORT', value: kerningGlyphs2[i][j][0] });
            result.fields.push({ name: 'valueRecord1', type: 'USHORT', value: kerningGlyphs2[i][j][1] });
            // console.log("Kerning: " + kerningGlyphs2[i][j][0] + " " + kerningGlyphs2[i][j][1])
        }
    }

    // Add Coverage tables (defines which first letters map to which PairSet table)
    result.fields.push({ name: 'coverageFormat', type: 'USHORT', value: 1 }); // Format 1 indicates glyph pairs (format 2 uses classes of glyphs)
    result.fields.push({ name: 'glyphCount', type: 'USHORT', value: firstGlyphs.length });
    for (let i = 0; i < firstGlyphs.length; i++) {
        result.fields.push({ name: 'UppercasePGlyphID', type: 'USHORT', value: firstGlyphs[i] });
    }

    return (result);
}

export default { parse: parseGposTable, make: makeGposTable };
